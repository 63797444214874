import * as yup from 'yup';
import { validatePhoneNumber } from 'schema/validation/phone-number';
import { validatePassword, validatePasswordConfirm } from 'schema/validation/password';

export const SETUP_MEMBERSHIP_ACCOUNT = yup.object().shape({
    password: yup.string().test('Password validation', validatePassword),
    passwordConfirm: yup.string().test('Password Confirm Matches', validatePasswordConfirm),
    email: yup.string().email('invalid').required('required'),
    phoneNumber: yup
        .string()
        .test('Phone Number validation', (value: string | undefined, context: yup.TestContext<Record<string, any>>) =>
            validatePhoneNumber(value, context, 'US')
        )
});
