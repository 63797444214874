import { ReactElement } from 'react';
import classNames from 'classnames';

import './bordered-container.style.scss';

interface BorderedContainerProps {
    children: React.ReactNode | React.ReactNode[];
    className?: string;
}

const BorderedContainer = ({ children, className }: BorderedContainerProps): ReactElement => {
    const containerClasses = classNames('bordered-container', { [`${className}`]: className });

    return <div className={containerClasses}>{children}</div>;
};

export default BorderedContainer;
