import { ReactElement } from 'react';
import classNames from 'classnames';
import UIStatuses from 'types/ui-statuses';

import './icon-background-color.style.scss';

export interface IconBackgroundColorProps {
    children: React.ReactNode;
    variant?: Extract<UIStatuses, 'primary' | 'warning' | 'danger'>;
    className?: string;
}

const IconBackgroundColor = ({ children, variant = 'primary', className }: IconBackgroundColorProps): ReactElement => {
    const containerClasses = classNames(
        'icon-background-color',
        { [`icon-background-color--${variant}`]: variant },
        { [`${className}`]: className }
    );

    return <div className={containerClasses}>{children}</div>;
};

export default IconBackgroundColor;
