import * as yup from 'yup';

export const PASSWORD_VALIDATION_TYPES = {
    ONE_LOWERCASE: {
        translationKey: 'oneLowercase'
    },
    ONE_UPPERCASE: {
        translationKey: 'oneUppercase'
    },
    ONE_SYMBOL: {
        translationKey: 'oneSymbol'
    },
    ONE_NUMBER: {
        translationKey: 'oneNumber'
    },
    MIN_LENGTH_8_CHAR: {
        translationKey: 'minLength'
    },
    CONFIRMATION_MATCH: {
        translationKey: 'confirmationMatch'
    },
    CHARACTERS_IN_A_ROW: {
        translationKey: 'charactersInARow'
    },
    AT_LEAST: {
        translationKey: 'atLeastMessage'
    }
};
const PASSWORD_VALIDATION = [
    {
        validate: (
            password: string | undefined,
            context: yup.TestContext<Record<string, any>>
        ): boolean | yup.ValidationError[] => {
            const { createError } = context;
            const errorLowercase = createError({ message: PASSWORD_VALIDATION_TYPES.ONE_LOWERCASE.translationKey });
            const errorUppercase = createError({ message: PASSWORD_VALIDATION_TYPES.ONE_UPPERCASE.translationKey });
            const errorSymbol = createError({ message: PASSWORD_VALIDATION_TYPES.ONE_SYMBOL.translationKey });
            const errorNumber = createError({ message: PASSWORD_VALIDATION_TYPES.ONE_NUMBER.translationKey });

            if (!password) return [errorLowercase];

            const failedValidations: yup.ValidationError[] = [];

            // Lowercase check
            if (!/[a-z]+/.test(password)) failedValidations.push(errorLowercase);

            // Uppercase check
            if (!/[A-Z]+/.test(password)) failedValidations.push(errorUppercase);

            // Symbol check
            if (!/[^a-zA-Z0-9]/.test(password)) failedValidations.push(errorSymbol);

            // Number check
            if (!/[0-9]+/.test(password)) failedValidations.push(errorNumber);

            // If two or more validations failed, return the array of errors
            if (failedValidations.length >= 2) {
                return failedValidations;
            }

            return true;
        }
    },
    {
        validate: (
            password: string | undefined,
            context: yup.TestContext<Record<string, any>>
        ): boolean | yup.ValidationError => {
            const { createError } = context;
            const error = createError({ message: PASSWORD_VALIDATION_TYPES.MIN_LENGTH_8_CHAR.translationKey });
            if (!password) return error;

            if (password.length >= 8) return true;

            return error;
        }
    },
    {
        validate: (
            password: string | undefined,
            context: yup.TestContext<Record<string, any>>
        ): boolean | yup.ValidationError => {
            const { createError } = context;
            const error = createError({ message: PASSWORD_VALIDATION_TYPES.CHARACTERS_IN_A_ROW.translationKey });
            if (!password) return error;
            // Validates there are no more than 2 identical characters in a row
            const hasRepeatedChars = password.split('').some((v, i, a) => {
                if (i <= 1) return false;
                return a[i - 1] === a[i] && a[i - 2] === a[i - 1];
            });

            if (!hasRepeatedChars) return true;
            return error;
        }
    }
];

//  runs through each password validation step and produces a validation error if one was returned from the validator
export const validatePassword = (
    password: string | undefined,
    context: yup.TestContext<Record<string, any>>
): boolean | yup.ValidationError => {
    if (!password) {
        return true;
    }
    const { createError } = context;

    //  maps the validators results or returns null which will be filtered out
    const errors = PASSWORD_VALIDATION.flatMap((validator) => {
        const result = validator.validate(password, context);
        if (Array.isArray(result)) {
            return result.map((err) => (err as yup.ValidationError).message);
        } else if (result && (result as yup.ValidationError).message) {
            return [(result as yup.ValidationError).message];
        }
        return [];
    });

    if (errors.length === 0) return true;

    //  joins all validation messages to be used by a consumer, i.e. PasswordRules
    return createError({ message: errors.join(',') });
};

//  validates that both passwords match
export const validatePasswordConfirm = (
    passwordConfirm: string | undefined,
    context: yup.TestContext<Record<string, any>>
): boolean | yup.ValidationError => {
    const { createError, parent } = context;
    const { password } = parent;
    const error = createError({ message: PASSWORD_VALIDATION_TYPES.MIN_LENGTH_8_CHAR.translationKey });

    if (!passwordConfirm || passwordConfirm !== password) return error;

    return true;
};

//  validates that both passwords match when updating password within profile
export const validateNewPasswordConfirm = (
    newPasswordConfirm: string | undefined,
    context: yup.TestContext<Record<string, any>>
): boolean | yup.ValidationError => {
    const { createError, parent } = context;
    const { newPassword } = parent;
    const error = createError({ message: PASSWORD_VALIDATION_TYPES.MIN_LENGTH_8_CHAR.translationKey });

    if (!newPasswordConfirm || newPasswordConfirm !== newPassword) return error;

    return true;
};
